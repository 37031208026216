import { create } from "zustand";
import { devtools } from "zustand/middleware";

type Event = {
  timeStamp: string;
  companyId?: string;
  userId?: string;
  type: string;
  data?: any;
}

interface EventsState {
  event: Event;

  setEvent: (event: Event) => void;
}

export const useEventsStore = create<EventsState>()(
  devtools((set) => ({
    event: {
      timeStamp: "date",
      companyId: "",
      type: "default",
    },

    setEvent: (event) =>
      set((state) => ({
        ...state,
        event: {
          timeStamp: event.timeStamp,
          companyId: event.companyId,
          userId: event.userId,
          type: event.type,
          data: event.data,
        },
      }))
  }))
);
