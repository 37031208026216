import { combineReducers } from "@reduxjs/toolkit";
import { App } from "./app";
import { Send } from "./send";
import { Requests } from "./requests";

const rootReducer = combineReducers({
  app: App,
  send: Send,
  requests: Requests,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
