import { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { Location } from "history";
import { connect } from "react-redux";
import { IonReactRouter } from "@ionic/react-router";
import { IntlProvider } from "react-intl";

import { User, Auth } from "./interfaces";
import AuthApi from "./api/auth";
import { deserialize } from "./utils/serialize";
import serialize from "./utils/serialize";

import { SocketProvider } from "./providers/socket";

// Pages
import HOCAuth from "./pages/auth/HOCAuth";
import HOCDashboard from "./pages/dashboard/HOCDashboard";

// Statics
import PrivacyPolicy from "./pages/statics/PrivacyPolicy";

import WebChat from "./components/ai/web-chat";

// Utils
import { removeAuth, setCompany } from "./utils/localStorage";
import {
  setAppUser,
  setAppLoading,
  cleanAppAuth,
  cleanAppUser,
} from "./actions/app";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Languages */
import es from "./lang/es";
import en from "./lang/en";
import pt from "./lang/pt";

/* old good bootstrap */
import "bootstrap/dist/css/bootstrap.min.css";

/* Theme variables */
import "./theme/variables.css";

/* Custom css */
import "./theme/custom.css";

setupIonicReact();

function loadLocaleData(locale: string) {
  switch (locale) {
    case "es":
      return es;
    case "pt":
      return pt;
    default:
      return en;
  }
}

const App: React.FC<Props> = (props: Props) => {
  const { user, loading, auth } = props;
  const { token, refreshToken, pushToken } = auth;
  const { language } = user;
  const messages = loadLocaleData(language);

  const fetchData = async () => {
    const { data, err } = await AuthApi.me();
    if (err) {
      await props.dispatch(setAppLoading(false));
    }
    if (data) {
      const { email, company, language, companies } = data.user;
      await setCompany({ company });
      await props.dispatch(setAppUser({ email, company, language, companies }));
      await props.dispatch(setAppLoading(false));
    }
  };

  const handleLogout = async () => {
    console.log("fire");
    await props.dispatch(setAppLoading(true));
    cleanUserAndAuth();
    if (refreshToken) {
      const logOutData = { refreshToken, pushToken };
      await AuthApi.logout(logOutData);
    }
    props.dispatch(setAppLoading(false));
    window.location.reload();
  };

  const cleanUserAndAuth = async () => {
    await props.dispatch(cleanAppUser());
    await props.dispatch(cleanAppAuth());
    await removeAuth();
  };

  useEffect(() => {
    if (token) {
      fetchData();
    } else {
      props.dispatch(setAppLoading(false));
      cleanUserAndAuth();
    }
  }, [token]);

  const redirectAuth = ({
    search,
    pathname,
  }: {
    search: any;
    pathname: string;
  }) => {
    const query = deserialize(search);
    const s = serialize(query) ? `?${serialize(query)}` : "";
    const to = {
      pathname: query.pathname
        ? query.pathname
        : ["/auth/register", "/auth/register-cloudbeds"].includes(pathname)
        ? "/ai"
        : "/",
      search: s,
    };
    return to;
  };

  const redirectDash = (search: any, pathname: string) => {
    const s = search
      ? `${search}&pathname=${pathname}`
      : `?pathname=${pathname}`;

    const to = {
      pathname: "/auth/login",
      search: s,
    };
    return to;
  };

  const redirectDoor = (location: Location, logged: boolean) => {
    const { search, pathname } = location;
    switch (pathname) {
      case "/door/invite":
        return {
          pathname: logged
            ? "/account/accept-invitation"
            : "/auth/register-user",
          search,
        };
      default:
      case "/door":
        return {
          pathname: logged ? "/integrations" : "/auth/register-cloudbeds",
          search,
        };
    }
  };

  const app = (
    <IonApp>
      <SocketProvider isAuthenticated={user.logged}>
        <IntlProvider locale={language} messages={messages}>
          <IonReactRouter>
            <Switch>
              <Route
                exact
                path="/statics/privacy-policy"
                component={PrivacyPolicy}
              />
              <Route exact path="/web-chat" component={WebChat} />
              <Route
                path="/auth"
                render={(props) => {
                  const { search, pathname } = props.location;
                  const to = redirectAuth({ search, pathname });
                  return user.logged ? (
                    <Redirect to={to} />
                  ) : (
                    <HOCAuth {...props} language={language} />
                  );
                }}
              />
              <Route
                path="/door"
                render={(props) => {
                  const to = redirectDoor(props.location, user.logged);
                  return <Redirect to={to} />;
                }}
              />
              <Route
                path="/"
                render={(props) => {
                  const { search, pathname } = props.location;
                  const to = redirectDash(search, pathname);
                  return user.logged ? (
                    <HOCDashboard
                      user={user}
                      logOut={handleLogout}
                      refreshUser={fetchData}
                      {...props}
                    />
                  ) : (
                    <Redirect to={to} />
                  );
                }}
              />
            </Switch>
          </IonReactRouter>
        </IntlProvider>
      </SocketProvider>
    </IonApp>
  );

  return loading ? null : app;
};

interface Props {
  dispatch: Function;
  user: User;
  auth: Auth;
  loading: boolean;
}

export default connect((props: any) => ({
  user: props.app.user,
  auth: props.app.auth,
  loading: props.app.loading,
}))(App);
