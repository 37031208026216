import { IonContent, IonPage, isPlatform, useIonToast } from "@ionic/react";
import React from "react";
import { IconRefresh } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Navbar } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

import styles from "../styles.module.css";
import ChatDetail from "../../ChatDetail";
import ChatInput from "../../common/chat-input";
import webChatStyles from "./styles.module.css";
import PlaygroundApi from "../../../api/playground";
import {
  usePlaygroundActions,
  usePlaygroundData,
} from "../../../store/playground";
import config from "../../../config";
import { ChatInputRef, Message } from "../../../interfaces";
import { connect } from "react-redux";
import InfoModal from "./modal";
import SkeletonText from "../../SkeletonText";
import { useSelectContactById } from "../../../store/contacts";
import { useSocketStore } from "../../../store/socket";

const isMobile = isPlatform("android") || isPlatform("ios");
const version = config.VERSION;

function WebChat() {
  const [content, setContent] = React.useState<string>();
  const [isFocus, setIsFocus] = React.useState(0);
  const chatInputRef = React.useRef<ChatInputRef>(null);
  const [replyTo, setReplyTo] = React.useState<Message | null>(null);

  const intl = useIntl();

  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const slug = params.get("slug");

  const { createWebId, setLoading } = usePlaygroundActions();

  const [present] = useIonToast();

  const existsSlug = slug !== null && slug.length > 0;

  const {
    data: companyData,
    isLoading: isGettingCompany,
    isError,
  } = useQuery({
    queryFn: () => PlaygroundApi.getCompanyInfo({ slug: String(slug) }),
    queryKey: ["getCompanyInfo", slug || ""],
    enabled: existsSlug,
    refetchOnWindowFocus: false,
  });

  const { setWebChatQuery } = useSocketStore();

  const { playground, loading } = usePlaygroundData(companyData?.company?._id);

  const selectedChat = useSelectContactById(String(playground?.contactId));

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    isPending: isSendingMessage,
  } = useMutation({
    mutationFn: PlaygroundApi.sendFreeMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  const { mutate: mutateClearPlayground, isPending: isClearingPlayground } =
    useMutation({
      mutationFn: PlaygroundApi.clearFreePlayground,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onSubmit = () => {
    if (content && playground?.contactId && playground?.companyId) {
      mutateSendMessage({
        contactId: playground.contactId,
        content,
        replyTo: replyTo?.id,
        companyId: playground.companyId,
      });
    }
  };

  const onBeforeClearPlayground = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (playground?.contactId && playground.companyId) {
      mutateClearPlayground({
        contactId: playground.contactId,
        companyId: playground.companyId,
      });
    }
  };

  const onCreateWebId = (name: string) => {
    if (companyData?.company?._id) {
      setLoading(true);
      createWebId(companyData.company._id, name, false);
    }
  };

  // If the company doesn't exist
  if (isError) {
    history.push("/");
  }

  React.useEffect(() => {
    if (!slug) {
      history.push("/");
    }
    chatInputRef.current?.setInputFocus();
  }, [slug]);

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
      setReplyTo(null);
    }
  }, [sendResponse]);

  React.useEffect(() => {
    if (playground?.contactId) {
      const query = {
        webChatId: playground?.contactId,
        ...version,
        mobile: isMobile,
      };
      setWebChatQuery(query);
    }
  }, [playground?.webId]);

  const inputFocused = () => setTimeout(() => setIsFocus(isFocus + 1), 650);

  return (
    <>
      <Helmet>
        <title>
          {`Visito | ${companyData?.company?.name || ""} Playground`}
        </title>
        <meta
          name="description"
          content="This is where you can experiment to understand exactly what your customers will experience from the AI's responses"
        />
        <meta
          property="og:title"
          content={`Visito | ${companyData?.company?.name || ""} Playground`}
        />
        <meta
          property="og:description"
          content="This is where you can experiment to understand exactly what your customers will experience from the AI's responses"
        />
        <meta
          property="og:image"
          content="https://cdn.prod.website-files.com/659ea70342c43623b694723a/65cf09976f795389644fd344_visito%20thumbnailV3.png"
        />
        <meta
          property="twitter:title"
          content={`Visito | ${companyData?.company?.name || ""} Playground`}
        />
        <meta
          property="twitter:description"
          content="This is where you can experiment to understand exactly what your customers will experience from the AI's responses"
        />
        <meta
          property="twitter:image"
          content="https://cdn.prod.website-files.com/659ea70342c43623b694723a/65cf09976f795389644fd344_visito%20thumbnailV3.png"
        />
      </Helmet>
      {!isGettingCompany &&
        !playground?.contactId &&
        !isError &&
        existsSlug && (
          <InfoModal
            companyName={companyData?.company?.name || ""}
            loading={loading}
            onSubmit={onCreateWebId}
          />
        )}
      <Navbar className={`${webChatStyles.headerContainer}`} fixed="top">
        <Container fluid className={isMobile ? "flex flex-column gap-3" : ""}>
          {isMobile ? (
            <>
              <div className="flex align-items-center gap-1 w-100">
                <Navbar.Brand>
                  <a href="/">
                    <div className="logo"></div>
                  </a>
                </Navbar.Brand>
                <h3
                  className="fs-4 mb-0 fw-semibold"
                  style={{ color: "var(--ion-text-color)" }}
                >
                  <FormattedMessage
                    id="aiCompanyTitle"
                    values={{ company: companyData?.company?.name || "" }}
                  />
                </h3>
              </div>
              <div className="flex align-items-center gap-3">
                {selectedChat.messages.length > 0 && (
                  <button
                    className={styles.resetBtn}
                    onClick={onBeforeClearPlayground}
                    disabled={isClearingPlayground}
                  >
                    <IconRefresh size={17} />
                    <FormattedMessage id="aiSettingsReset" />
                  </button>
                )}
              </div>
            </>
          ) : (
            <>
              <Navbar.Brand>
                <a href="/">
                  <div className="logo"></div>
                </a>
              </Navbar.Brand>
              <div className="flex align-items-center gap-3">
                <h3
                  className="fs-4 mb-0"
                  style={{ color: "var(--ion-text-color)" }}
                >
                  <FormattedMessage
                    id="aiCompanyTitle"
                    values={{ company: companyData?.company?.name || "" }}
                  />
                </h3>
                {selectedChat.messages.length > 0 && (
                  <button
                    className={styles.resetBtn}
                    onClick={onBeforeClearPlayground}
                    disabled={isClearingPlayground}
                  >
                    <IconRefresh size={17} />
                    <FormattedMessage id="aiSettingsReset" />
                  </button>
                )}
              </div>
              <Link className={styles.resetBtn} to="/auth/register">
                <FormattedMessage id="common.signUp" />
              </Link>
            </>
          )}
        </Container>
      </Navbar>
      <IonPage id="main-content">
        <IonContent>
          <div className="page-wrapper">
            {isGettingCompany ? (
              <div
                className={
                  isMobile
                    ? `${webChatStyles.webChatWrapperMobile} px-4`
                    : webChatStyles.webChatWrapper
                }
              >
                <SkeletonText rows={18} />
              </div>
            ) : (
              <div
                className={
                  isMobile
                    ? webChatStyles.webChatWrapperMobile
                    : webChatStyles.webChatWrapper
                }
              >
                <div className={styles.chatDetailContainer}>
                  <div className={`h-100 w-100 ${isMobile ? "px-3" : ""}`}>
                    <ChatDetail
                      messages={selectedChat.messages}
                      openImage={(link) => console.log("Open image", link)}
                      contactId={selectedChat.contact.id}
                      mode={isMobile ? "ios" : "desktop"}
                      scrollEscalations={1}
                      type="playground"
                      companySlug={String(slug)}
                      replyTo={({ message }: { message: Message }) => {
                        setReplyTo(message);
                        inputFocused();
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.chatInputContainer} ${
                    selectedChat.messages.length === 0 ? "pt-3" : undefined
                  }`}
                >
                  <ChatInput
                    id="send-body-playground"
                    ref={chatInputRef}
                    value={content}
                    placeholder={intl.formatMessage({
                      id: "chats.newMessageInput",
                    })}
                    enableWaActions={false}
                    onInputChange={onChangeContent}
                    onBeforeSubmit={onSubmit}
                    onAfterFocus={() => (isMobile ? inputFocused() : undefined)}
                    replyTo={replyTo}
                    cleanReplyTo={() => setReplyTo(null)}
                    //disabled={isSendingMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}

export default connect((props: any) => ({
  requests: props.requests,
  settings: props.app.settings,
}))(WebChat);
