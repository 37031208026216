import { AsYouType } from "libphonenumber-js";

import getSvgs from "./messageSvgs";
import {
  apiResult,
  Contact,
  ContactsDBObject,
  messageApi,
  Message,
} from "../interfaces";
import {
  getTimeStampForContact,
  getTimeStampForMessage,
  getTimeStampForMessageGrouping,
} from "./moment";

export function howMany(messages: messageApi[], roles: string[]) {
  let lastTime: string | null = null;
  let lastMessage: string | null = null;
  let unread = 0;
  let count = 0;

  const rolesSet = new Set(roles);

  for (const message of messages) {
    if (!message.isRead) {
      unread++;
    }
    if (rolesSet.has(message.role)) {
      count++;
    }
    lastTime = message.createAt;
    lastMessage = message.content;
  }

  return {
    count,
    lastTime,
    lastMessage: lastMessage || "",
    unread,
  };
}

export function runChatList(contacts: ContactsDBObject) {
  const list: Contact[] = [];
  let escalated = 0;
  let manual = 0;

  Object.values(contacts).forEach((value) => {
    if (value.contact.count) {
      list.push(value.contact);
    }
    if (value.contact.escalated) {
      escalated++;
    }
    if (value.contact.manual) {
      manual++;
    }
  });

  list.sort((a, b) => b.timestampNative - a.timestampNative);

  return {
    list,
    escalated,
    manual,
  };
}

export function getContactType(contact: apiResult): string {
  const typeMap: { [key: string]: string } = {
    igId: "ig",
    fmId: "fm",
    calryId: "ab",
    phoneNumberId: "wa",
    webId: "web",
    callId: "voice",
  };

  for (const key in typeMap) {
    if (contact[key]) {
      return typeMap[key];
    }
  }

  return "play";
}

function mapCompanies(companies: apiResult[]) {
  return companies.map((company) => ({
    name: company.name || "",
    id: company._id || "",
  }));
}

function mapMessages(messages: apiResult[]) {
  function buildMessage(message: apiResult): apiResult {
    return {
      id: message._id || "",
      content: message.content || "",
      right: message.role === "assistant" || message.role === "outbound",
      status: message.status || "",
      escalated: Boolean(message.escalated),
      timestamp: getTimeStampForMessage(message.createAt),
      timestampGrouping: getTimeStampForMessageGrouping(message.createAt),
      svg: getSvgs(message.status),
      interactive: {
        flag: Boolean(message.interactive),
        data: message.interactive || null,
      },
      replyTo: message.meta?.replyTo
        ? buildMessage(message.meta?.replyTo)
        : null,
      isManual: Boolean(message.meta?.user),
      error: message.meta?.error || "",
    };
  }

  return messages.map((m) => buildMessage(m));
}

function mapBookings(bookings: apiResult[]) {
  return bookings.map((b) => ({
    phoneNumber: b.phoneNumber || "",
    status: b.status || "",
    externalId: b.externalId || "",
    fullName: b.fullName || "",
    from: b.from || "",
    to: b.to || "",
    locationName: b.locationName || "",
    rooms: b.rooms || [],
    conversion: Boolean(b.conversion),
  }));
}

function mapEscalations(escalations: apiResult[], type: string) {
  return escalations.map((e) => ({
    type: e.type || "",
    open: Boolean(e.open),
    details: e.details || "",
    escalated: e.open && type !== "play",
  }));
}

export function adaptItem(c: apiResult, properties: apiResult) {
  const isManual = c.ai?.isManual || false;
  const isBook = c.ai?.isBook || false;
  const type = getContactType(c);

  const contact = {
    name: c.fullName,
    timestamp: getTimeStampForContact(properties.lastTime),
    timestampNative: new Date(properties.lastTime).getTime(),
    preview: properties.lastMessage || "",
    id: c._id,
    unread: properties.unread || 0,
    enabled: c.enabled,
    manual: !c.enabled,
    optedOut: c.optedOut || false,
    phoneNumber: new AsYouType().input(`+${c.phoneNumber}`),
    status: c.status || "",
    blocked: Boolean(c.blocked),
    count: properties.count || 0,
    type,
    followers: c.meta?.followerCount || 0,
    username: c.meta?.username || "",
    isGuest: Boolean(c.bookings?.length),
    isBook,
    isManual,
    escalated: false,
    isConversion: false,
  };

  const companies = mapCompanies(c.companies);
  const messages = mapMessages(c.messages);
  const bookings = mapBookings(c.bookings);
  const escalations = mapEscalations(c.escalations, type);

  const isConversion = bookings.some((b) => b.conversion);
  const escalated = escalations.some((e) => e.open && type !== "play");

  return {
    contact: {
      ...contact,
      escalated,
      isConversion,
      companies,
    },
    messages,
    bookings,
    escalations,
    companies,
  };
}
