import { useEffect } from "react";
import { Event } from "../interfaces";
import config from "../config";
import { useEventsStore } from "../store/events";
const { CDN_ROOT } = config;
const ringer = require("../sounds/not.mp3");

const DesktopNotification: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { request } = props;

  const { setEvent, event } = useEventsStore();

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      request && Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const audio = new Audio(ringer);
    const sendNotification = (data: any) => {
      const { notification, payload } = data;

      const not = new Notification(`${notification.title}`, {
        body: notification.body,
        icon: `${CDN_ROOT}/statics/logo/letters-square.png`,
        badge: `${CDN_ROOT}/statics/logo/letters-square.png`,
        // image: `${CDN_ROOT}/statics/logo/letters-square.png`,
      });

      not.onclick = (event) => {
        setEvent({
          timeStamp: new Date().toString(),
          data: payload,
          type: "chat",
          companyId: `undefined`,
        });
        not.close();
      };
    };

    if (event.type === "not-escalation") {
      sendNotification(event.data);
      audio.play();
    }

    if (event.type === "not-new-message") {
      sendNotification(event.data);
      audio.play();
    }
  }, [event]);

  return <></>;
};

interface ILayoutProps {
  request: boolean;
  setEvent: Function;
  event: Event;
}

export default DesktopNotification;
