import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useSocketStore } from "../store/socket";
import { createSocket } from "../utils/socket";
import { useEventsStore } from "../store/events";
import useContactsStore from "../store/contacts";
import { setSettings } from "../actions/app";
import { useCallEventsStore } from "../store/call";

interface SocketContextType {
  userSocket: ReturnType<typeof createSocket> | null;
  webChatSocket: ReturnType<typeof createSocket> | null;
}

const SocketContext = createContext<SocketContextType | null>(null);

const connectAndSetupSocket = (
  socket: ReturnType<typeof createSocket>,
  eventHandlers: Record<string, (data: any) => void>,
  intervalEvents?: { name: string; interval: number, query: any}[],
  cleanupEvents?: string[]
) => {
  socket.connect();

  // Configurate dynamic events
  Object.entries(eventHandlers).forEach(([event, handler]) => {
    socket.on(event, handler);
  });

  // Configure heartbeats
  const intervals: NodeJS.Timer[] = [];
  if (intervalEvents) {
    intervalEvents.forEach(({ name, interval, query }) => {
      if (query) {
        const timer = setInterval(() => {
          socket.emit(name, query);
        }, interval);
        intervals.push(timer);
      }
    });
  }

  // Clean socket + custom clean events
  return () => {
    Object.keys(eventHandlers).forEach((event) => socket.off(event));

    if (cleanupEvents) {
      cleanupEvents.forEach((event) => socket.off(event));
    }

    intervals.forEach((timer) => clearInterval(timer));

    socket.disconnect();
  };
};

export const SocketProvider: React.FC<{
  children: React.ReactNode;
  isAuthenticated: boolean;
}> = ({ children, isAuthenticated }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    userQuery,
    webChatQuery,
    setUserSocketReady,
    setWebChatSocketReady,
  } = useSocketStore();

  const { setEvent } = useEventsStore();
  const { updateContactsDb } = useContactsStore();
  const { setCallServerEvent } = useCallEventsStore();

  const userSocket = useMemo(() => {
    if (userQuery && userQuery?.email) {
      return createSocket(userQuery);
    }
    return null;
  }, [userQuery]);
  const webChatSocket = useMemo(() => {
    if (webChatQuery && webChatQuery?.webChatId) {
      return createSocket(webChatQuery);
    }
    return null;
  }, [webChatQuery]);

  // User socket
  useEffect(() => {
    if (!isAuthenticated || !userSocket) return;

    const cleanupUserSocket = connectAndSetupSocket(
      userSocket,
      {
        connect: () => {
          setUserSocketReady(true);
          console.log("Main socket connected");
        },
        disconnect: () => {
          setUserSocketReady(false);
          console.log("Main socket disconnected");
        },
        "not-escalation": (message) => {
          const { data, timeStamp, companyId } = message;
          setEvent({ timeStamp, companyId, type: "not-escalation", data })
        },
        "not-new-message": (message) => {
          const { data, timeStamp, companyId } = message;
          setEvent({ timeStamp, companyId, type: "not-new-message", data })
        },
        "new-message": (message) => {
          const { data, timeStamp, companyId } = message;
          if (message.data) {
            setEvent({ timeStamp, companyId, type: "new-message", data: [] })
            updateContactsDb(data);
          }
        },
        "company-message": (message) => {
          const { data, timeStamp, companyId } = message;
          switch (data.task) {
            case "settings":
              dispatch(setSettings(data.message));
              break;
            default:
              setEvent({ timeStamp, companyId, type: "company-message", data })
          }
        },
        "call-event": (message) => {
          setCallServerEvent(message);
        },
        "user-message": (message) => {
          const { data, timeStamp, userId } = message;
          setEvent({ timeStamp, userId, type: "user-message", data })
        },
        "updateBrowser": () => {
          if (Capacitor.isNativePlatform()) {
            history.push(`/download-new-version`);
          } else {
            window.location.reload();
          }
        }
      },
      [],
      // [{ name: "heartBeat", interval: 60000, query: userQuery }],
      ["connect", "disconnect", "new-message", "initial", "cloudbeds-setup"]
    );

    return () => {
      cleanupUserSocket();
    };
  }, [isAuthenticated, userSocket, userQuery, setUserSocketReady]);

  // WebChat socket
  useEffect(() => {
    if (!webChatSocket) return;

    const cleanupWebChatSocket = connectAndSetupSocket(
      webChatSocket,
      {
        connect: () => {
          setWebChatSocketReady(true);
          console.log("WebChat socket connected");
        },
        disconnect: () => {
          setWebChatSocketReady(false);
          console.log("WebChat socket disconnected");
        },
        "new-message": (message) => {
          const { data, timeStamp, companyId } = message;
          if (message.data) {
            setEvent({ timeStamp, companyId, type: "new-message", data: [] })
            updateContactsDb(data);
          }
        },
      },
      [],
      // [{ name: "heartBeat", interval: 60000, query: webChatQuery }],
      ["connect", "disconnect", "new-message"]
    );

    return () => {
      cleanupWebChatSocket();
    };
  }, [webChatSocket, webChatQuery, setWebChatSocketReady]);

  return (
    <SocketContext.Provider value={{ userSocket, webChatSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSockets = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSockets must be used within a SocketProvider");
  }
  return context;
};
