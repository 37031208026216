import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { CallEvent, CallServerEvent } from "../interfaces";

interface CallEventsState {
  callEvent: CallEvent;
  callServerEvent: CallServerEvent;

  setCallEvent: (event: CallEvent) => void;
  setCallServerEvent: (event: CallServerEvent) => void;
}

export const useCallEventsStore = create<CallEventsState>()(
  devtools((set) => ({
    callEvent: {
      contactId: "",
    },
    callServerEvent: {
      event: null,
    },

    setCallEvent: (event) =>
      set((state) => ({
        ...state,
        callEvent: event,
      })),

    setCallServerEvent: (event) =>
      set((state) => ({
        ...state,
        callServerEvent: event,
      })),
  }))
);
