import { useMemo } from "react";
import PlaceholderReplacer from "../../outbound/templates/placeholder";
import { Message } from "../../../interfaces";

import styles from "./styles.module.css";

import { IconCameraFilled, IconMessage } from "@tabler/icons-react";

function ReplyContent({
  message,
  right,
  onAfterClick,
}: {
  message: Message;
  right: boolean;
  onAfterClick: Function;
}) {
  const { interactive, content } = message;
  const { flag, data } = interactive || {};
  const body = flag ? data?.body || content : content;

  const renderContent = useMemo(() => {
    switch (interactive?.data?.header?.type) {
      case "image": {
        const { image } = interactive.data.header;
        return (
          <div className={styles.content}>
            <div className={styles.icon}>
              <IconCameraFilled size={12} />
            </div>
            <div className={styles.text}>
              <PlaceholderReplacer template={body} placeholders={[]} />
            </div>
            <div className={styles.image}>
              <img
                alt=""
                src={
                  image?.url?.includes("https://")
                    ? image.url
                    : `https://s3.visitoai.com/${image.url}`
                }
              />
            </div>
          </div>
        );
      }

      case "audio":
      case "story":
      case "file":
      case "document":
      case "call": {
        return (
          <div className={styles.content}>
            <div className={styles.icon}>
              <IconMessage size={12} />
            </div>
            <div className={styles.text}>
              <PlaceholderReplacer template={body} placeholders={[]} />
            </div>
          </div>
        );
      }

      default:
        return (
          <div className={styles.content}>
            <div className={styles.text}>
              <PlaceholderReplacer template={body} placeholders={[]} />
            </div>
          </div>
        );
    }
  }, [interactive?.data?.header?.type, body]);

  return (
    <div
      className={
        right
          ? `${styles.container} ${styles.right}`
          : `${styles.container} ${styles.left}`
      }
      onClick={() => onAfterClick(message.id)}
    >
      {renderContent}
    </div>
  );
}

export default ReplyContent;
