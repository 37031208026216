import { useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonChip,
} from "@ionic/react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { IconPlus } from "@tabler/icons-react";

import { Contact, Settings, Filter } from "../../../interfaces";

import "./Chats.css";

//Components
import ChatList from "../../../components/ChatList";
import SkeletonLoader from "../../../components/SkeletonLoader";

//API
import ContactsApi from "../../../api/contacts";

//Actions
import { setRequest } from "../../../actions/requests";
import useContactsStore from "../../../store/contacts";
import { useSocketStore } from "../../../store/socket";

const list: Contact[] = [];

const NewConversation = lazy(
  () => import("../../../components/new-conversation")
);

const Chats: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { settings } = props;

  const { userSocketLoading: loading } = useSocketStore();

  const [searchActive, setSearchActive] = useState(false);
  const [results, setResults] = useState(list);
  const [isOpenNewConv, setOpenNewConv] = useState<boolean>(false);

  const { contactList: contacts, escalatedCount, filters, setFilters } = useContactsStore();

  const intl = useIntl();

  const handleSearch = async (
    event: CustomEvent<{ value?: string | null | undefined }>
  ) => {
    if (event.detail.value) {
      const { data, err } = await ContactsApi.search(
        event.detail.value,
        settings.companies
      );
      if (data) {
        setSearchActive(true);
        setResults(
          data.map((d) => ({
            ...d,
            type: d.callId
              ? "voice"
              : d.igId
              ? "ig"
              : d.fmId
              ? "fm"
              : d.calryId
              ? "ab"
              : "wa",
          }))
        );
      }
      console.log(err);
    } else {
      setSearchActive(false);
    }
  };

  const handleFilterTap = ({
    field,
    checked,
    type,
  }: {
    field: keyof Contact | string;
    checked: boolean;
    type: keyof Filter;
  }) => {
    const updatedCheckedList = checked
      ? {
          ...filters,
          [type]:
            type === "simple"
              ? [...filters.simple, field as keyof Contact]
              : [...filters.companies, field as string],
        }
      : {
          ...filters,
          [type]:
            type === "simple"
              ? filters.simple.filter((item) => item !== field)
              : filters.companies.filter((item) => item !== field),
        };

    setFilters(updatedCheckedList);
  };

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <FormattedMessage id="nav.inbox" />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true} className="chat-mobile-wrapper">
          <div className="chat-interface">
            <div className="chat-interface-nav">
              <div className="flex gap-2 align-items-center searchbar-container">
                <IonSearchbar
                  debounce={100}
                  placeholder={intl.formatMessage({
                    id: "chats.searchContact",
                  })}
                  onIonInput={(ev) => handleSearch(ev)}
                  className={classNames({
                    "search-is-active": searchActive === true,
                  })}
                  inputmode={`text`}
                  mode={`ios`}
                  style={{ zIndex: 5000 }}
                  showCancelButton="focus"
                  onIonFocus={(e) => {
                    setSearchActive(true);
                  }}
                  onIonClear={(e) => {
                    setSearchActive(false);
                    setResults([]);
                  }}
                  onIonBlur={(e) => {
                    if (!results.length) {
                      setSearchActive(false);
                    }
                  }}
                />
                {!searchActive && settings.isWaReady && (
                  <>
                    <IconPlus
                      role="button"
                      color="var(--visito-color-1)"
                      size={26}
                      onClick={() => setOpenNewConv(true)}
                    />
                    <Suspense fallback="Loading...">
                      <NewConversation
                        isOpen={isOpenNewConv}
                        onOpenChange={setOpenNewConv}
                        settings={settings}
                        id="new-conversation-mobile-modal"
                      />
                    </Suspense>
                  </>
                )}
              </div>
              <div
                className="chat-interface-nav-review fw-semibold-1"
                style={{
                  display: searchActive ? "none" : "block",
                }}
              >
                <IonChip
                  onClick={() => setFilters({ simple: [], companies: [] })}
                  color={
                    !filters.simple.length && !filters.companies.length
                      ? undefined
                      : "medium"
                  }
                >
                  <FormattedMessage id="chats.all" />
                </IonChip>
                {["escalated", "manual", "isBook", "isManual"].map((s) => (
                  <IonChip
                    key={s}
                    onClick={() =>
                      setFilters({ ...filters, simple: [s as keyof Contact] })
                    }
                    color={
                      filters.simple.includes(s as keyof Contact)
                        ? undefined
                        : "medium"
                    }
                  >
                    <FormattedMessage id={`chats.${s}`} />{" "}
                    {s === "escalated" && <>({escalatedCount})</>}
                  </IonChip>
                ))}
                {settings.companies.length > 1
                  ? settings.companies.map((c) => (
                      <IonChip
                        key={c.id}
                        onClick={() => {
                          const newFilter = { ...filters, companies: [c.id] };
                          setFilters({ ...filters, companies: [c.id] });
                          props.dispatch(setRequest({ filter: newFilter }));
                        }}
                        color={
                          filters.companies.includes(c.id)
                            ? undefined
                            : "medium"
                        }
                      >
                        {c.name}
                      </IonChip>
                    ))
                  : null}
              </div>
            </div>

            {loading ? (
              <SkeletonLoader rows={10} />
            ) : (
              <ChatList
                contacts={searchActive ? results : contacts}
                mode={"mobile"}
                //changeStatus={changeStatusContact}
                isSearch={searchActive}
                filters={filters}
                isMulti={settings.multiAccount}
              />
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

interface ILayoutProps {
  dispatch: Function;
  settings: Settings;
}

export default connect((props: any) => ({
  settings: props.app.settings,
}))(Chats);
