import { useState } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  IonToggle,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import SkeletonText from "../../../components/SkeletonText";

// Api
import AiApi from "../../../api/ai";

// Logos
import ios from "../../../theme/images/ios-download.svg";
import android from "../../../theme/images/android-download.svg";

// Config
import config from "../../../config";

const industries = [
  { name: "Fotography", id: "65e67979d5b7ede8a864656c" },
  { name: "Visito", id: "65e6797ad5b7ede8a8646577" },
  { name: "Apartments", id: "65e6797ad5b7ede8a864657c" },
  { name: "Hospitality", id: "65e3b6600e31462d3bbd1fca" },
  { name: "General Purpose", id: "65efa5a15c6cbc982a52056f" },
  { name: "Visito Transcript", id: "65f4d2a220fe497ccaafdb5a" },
  { name: "Food & Beverage", id: "660afa08b1645256c71b99cf" },
  { name: "Padel & Tennis", id: "6679ef396745a33ffdacd63b" },
  { name: "Health & Fitness", id: "6679f3a16745a33ffdacd653" },
  { name: "Hospitality Bookings", id: "679aa2ee06a193645d6ab9f5" },
];

interface AiSetting {
  introduceAsBot: boolean;
  model: string;
  industry: string;
}

const AiSettings: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(true);
  const [ai, setAi] = useState<AiSetting>({
    introduceAsBot: false,
    model: "",
    industry: "",
  });

  useIonViewDidEnter(() => {
    requestAiSettings();
  });

  const handleAutoChange = async (event: CustomEvent<{ checked: boolean }>) => {
    const { data } = await AiApi.putAiSettingsByField(
      "introduceAsBot",
      event.detail.checked
    );
    setAi({ ...ai, introduceAsBot: data.aiSettings.introduceAsBot });
  };

  const handleDropDownChange = async (field: string, value: string) => {
    const { data } = await AiApi.putAiSettingsByField(field, value);
    setAi({ ...ai, [field]: data.aiSettings[field] });
  };

  const requestAiSettings = async () => {
    const { data } = await AiApi.getAiSettings();
    if (data) {
      setAi({
        ...ai,
        introduceAsBot: data.aiSettings.introduceAsBot,
        model: data.aiSettings.model,
        industry: data.aiSettings.industry,
      });
    }
    setLoading(false);
  };

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper container">
          <h1 className="page-title">
            <FormattedMessage
              id="ai-settings.title"
              defaultMessage="Ai Settings"
            />
          </h1>

          <div className="visito-card visito-block">
            <div className="visito-card-header">
              <div className="visito-card-title">
                <FormattedMessage id="ai-settings.paneTitle" />
              </div>
            </div>
            <div className="visito-card-body">
              <div className="visito-card-list">
                <div>
                  <h5>
                    <FormattedMessage id="ai-settings.businessInfo" />
                  </h5>
                  <p>
                    <FormattedMessage id="ai-settings.businessInfoDescription" />
                  </p>
                </div>
                <div>
                  <Link to={`/ai-settings/general`}>
                    <button className="btn btn-sm btn-light">
                      <IconChevronRight />
                    </button>
                  </Link>
                </div>
              </div>
              <hr />

              <div className="visito-card-list">
                <div>
                  <h5>
                    <FormattedMessage id="ai-settings.guestInfo" />
                  </h5>

                  <p>
                    <FormattedMessage id="ai-settings.guestInfoDescription" />
                  </p>
                </div>
                <div>
                  <Link to={`/ai-settings/customer`}>
                    <button className="btn btn-sm btn-light fw">
                      <IconChevronRight />
                    </button>
                  </Link>
                </div>
              </div>
              <hr />

              <div className="visito-card-list">
                <div>
                  <h5>
                    {" "}
                    <FormattedMessage id="ai-settings.notifications" />
                  </h5>
                  <p>
                    <FormattedMessage id="ai-settings.notificationsDescription" />
                  </p>
                </div>
                <div className="text-right">
                  <a
                    href="https://apps.apple.com/mx/app/visito-ai/id6475635950?l=en-GB"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img width="100px" src={ios} alt="App Store" />
                  </a>{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=visito.app.production&hl=en-US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img width="100px" src={android} alt="Play Store" />
                  </a>
                </div>
              </div>
              {(config.ENV === "staging" || config.ENV === "local") && (
                <>
                  <hr />
                  <div className="visito-card-list">
                    <div>
                      <h5>System Configuration</h5>
                      <p>Adjust asistant behaviour </p>
                    </div>
                    <div>
                      <Link to={`/ai-settings-system`}>
                        <button className="btn btn-sm btn-light">
                          <IconChevronRight />
                        </button>
                      </Link>
                    </div>
                  </div>
                  <hr />
                  <div className="visito-card-list">
                    {loading ? (
                      <SkeletonText rows={2} />
                    ) : (
                      <>
                        <div>
                          <h5>Introduction as Lana</h5>
                          <p>Reveal Lana to customers in first interaction.</p>
                        </div>
                        <div>
                          <IonToggle
                            checked={ai.introduceAsBot}
                            onIonChange={handleAutoChange}
                            mode="ios"
                            className="ios visito-toggle"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="visito-card-list">
                    {loading ? (
                      <SkeletonText rows={2} />
                    ) : (
                      <>
                        <div>
                          <h5>Model</h5>
                          <p>Select openai's model.</p>
                        </div>
                        <div>
                          <IonSelect
                            value={ai.model}
                            interface="popover"
                            onIonChange={(ev) =>
                              handleDropDownChange("model", ev.detail.value)
                            }
                          >
                            <IonSelectOption value="gpt4-o">
                              OpenAI GPT-4o
                            </IonSelectOption>
                            <IonSelectOption value="o1">
                              OpenAI o1
                            </IonSelectOption>
                          </IonSelect>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="visito-card-list">
                    {loading ? (
                      <SkeletonText rows={2} />
                    ) : (
                      <>
                        <div>
                          <h5>Industry</h5>
                          <p>Select industry.</p>
                        </div>
                        <div>
                          <IonSelect
                            value={ai.industry}
                            interface="popover"
                            onIonChange={(ev) =>
                              handleDropDownChange("industry", ev.detail.value)
                            }
                          >
                            {industries.map((o) => (
                              <IonSelectOption value={o.id} key={o.id}>
                                {o.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
interface ILayoutProps {}
export default AiSettings;
