import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isPlatform, useIonToast } from "@ionic/react";
import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";

// Config
import config from "../../config";

// Interfaces
import {
  User,
  Event,
  Request,
  Settings,
} from "../../interfaces";

// Actions
import {
  setSettings,
  setAppPushToken,
} from "../../actions/app";

// API
import SettingsApi from "../../api/settings";
import AuthApi from "../../api/auth";

//Pages
import MobileHome from "./MobileHome";
import DesktopHome from "./DesktopHome";

import Nots from "../../components/DesktopNotification";
import Push from "../../components/PushNotification";
import GA from "../../components/GoogleAnalytics";
import { usePlaygroundActions } from "../../store/playground";
import { setCompany } from "../../utils/localStorage";
import { useSocketStore } from "../../store/socket";
import { useSockets } from "../../providers/socket";
import { useEventsStore } from "../../store/events";
import { useCallEventsStore } from "../../store/call";

const version = config.VERSION;

const mobile = isPlatform("android") || isPlatform("ios");

const HOCDashboard: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const history = useHistory();
  const { user, logOut, requests, refreshUser, pushToken } =
    props;
  const [present] = useIonToast();

  const { createWebId } = usePlaygroundActions();
  const { setUserQuery, userSocketLoading, setUserSocketLoading } = useSocketStore();
  const { userSocket } = useSockets();
  const { event } = useEventsStore();
  const { callEvent } = useCallEventsStore();

  const getSettings = async () => {
    const { data, err } = await SettingsApi.get();
    if (data) {
      props.dispatch(setSettings(data.settings));
    }
    if (err) {
      props.dispatch(setSettings({}));
    }
  };

  const switchCompany = async (companyId: string) => {
    const { data, err } = await AuthApi.switchCompany({ companyId });
    if (data) {
      const { company } = data.user;
      await setCompany({ company });
      window.location.reload();
    }
    if (err) {
      return present({
        message: "Something went wrong",
        duration: 5000,
        color: "danger",
        position: "top",
      });
    }
  };

  useEffect(() => {
    const query = { email: user.email, ...version, mobile };
    setUserQuery(query)
  }, [])

  useEffect(() => {
    getSettings();
  }, [props.dispatch]);

  useEffect(() => {
    const { userId, data, companyId } = event;
    if (companyId && userSocketLoading) {
      setUserSocketLoading(false);
    }
    if (userId && data && data.task === "logout") {
      window.location.reload();
    }
  }, [event]);

  useEffect(() => {
    const { contactId, filter } = requests.request;
    if (contactId || filter) {
      console.log("emiting.....");
      const emitRequest = () => {
        if (userSocket?.connected) {
          userSocket?.emit("requests", {
            request: requests.request,
            user: user,
          });
        } else {
          console.log("Socket not connected, waiting...");
          userSocket?.once("connect", emitRequest);
        }
      };
      emitRequest();
    }
  }, [requests]);

  useEffect(() => {
    const { eventName } = callEvent;
    if (userSocket?.connected && eventName) {
      userSocket?.emit(eventName, {
        callEvent,
        user: user,
      });
    }
  }, [callEvent]);

  // For creating the web id -> playground
  useEffect(() => {
    createWebId(user.company.id);
  }, []);

  const pushEvent = async (event: Event) => {
    switch (event.type) {
      case "chat":
        mobile && event.data && history.push(`/chat/${event.data.id}`);
        !mobile && event.data && history.push(`/chats?chat=${event.data.id}`);
        break;
    }
  };

  return mobile ? (
    <>
      <GA />
      <Push
        request={Capacitor.isNativePlatform()}
        setEvent={pushEvent}
        setPushToken={(token: string) =>
          props.dispatch(setAppPushToken({ token }))
        }
        pushToken={pushToken}
      />
      <Route
        path="/"
        render={(props) => (
          <MobileHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            user={user}
            event={event}
            onRequestSettings={() => getSettings()}
            switchCompany={switchCompany}
          />
        )}
      />
    </>
  ) : (
    <>
      <GA />
      <Nots event={event} request={!mobile} setEvent={pushEvent} />
      <Route
        path="/"
        render={(props) => (
          <DesktopHome
            {...props}
            logOut={logOut}
            refreshUser={refreshUser}
            switchCompany={switchCompany}
            user={user}
            event={event}
            onRequestSettings={() => getSettings()}
          />
        )}
      />
    </>
  );
};

interface ILayoutProps {
  user: User;
  logOut: Function;
  refreshUser: Function;
  dispatch: Function;
  settings: Settings;
  requests: {
    request: Request;
  };
  pushToken: String;
}

export default connect((props: any) => ({
  requests: props.requests,
  settings: props.app.settings,
  pushToken: props.app.auth.pushToken,
}))(HOCDashboard);
