import { io, Socket } from "socket.io-client";

import config  from '../config';

export const createSocket = (query: Record<string, any>): Socket => {
  return io(config.SOCKET_ROOT, {
    // transports: ["websocket"],
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 20,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 60000,
    query,
    // auth: { token }
  });
};
