import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface SocketStore {
  userQuery: Record<string, any>;
  webChatQuery: Record<string, any>;
  userSocketReady: boolean;
  webChatSocketReady: boolean;
  userSocketLoading: boolean;

  setUserSocketReady: (userSocketReady: boolean) => void;
  setWebChatSocketReady: (webChatSocketReady: boolean) => void;
  setUserQuery: (query: Record<string, any>) => void;
  setWebChatQuery: (query: Record<string, any>) => void;
  setUserSocketLoading: (userSocketLoading: boolean) => void;
}

export const useSocketStore = create(
  devtools<SocketStore>((set) => ({
    userQuery: {},
    webChatQuery: {},
    userSocketReady: false,
    webChatSocketReady: false,
    userSocketLoading: true,

    setUserSocketReady: (userSocketReady) => set({ userSocketReady }),
    setWebChatSocketReady: (webChatSocketReady) => set({ webChatSocketReady }),
    setUserQuery: (query) => set({ userQuery: query }),
    setWebChatQuery: (query) => set({ webChatQuery: query }),
    setUserSocketLoading: (userSocketLoading) => set({ userSocketLoading }),
  }))
);
