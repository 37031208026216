import { createRef, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonFooter,
  IonToggle,
  useIonToast,
} from "@ionic/react";

import { validateMessage } from "../../../utils/validators";
import { setSendLoading } from "../../../actions/send";
import { setRequest } from "../../../actions/requests";

import ContactsApi from "../../../api/contacts";
import ChatDetail from "../../../components/ChatDetail";
import UploadPhotoModal from "../../../components/UploadPhotoModal";
import MediaModal from "../../../components/MediaModal";
import FileInput from "../../../components/common/file-input";
import ChatInput from "../../../components/common/chat-input";

import { ChatInputRef, Settings, Message } from "../../../interfaces";

import UserSvg from "../../../theme/images/user.svg";
import SparksSvg from "../../../theme/images/sparks.svg";

import "./Chat.css";
import { useSelectContactById } from "../../../store/contacts";

const Chat: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { history } = props;
  const chatInputRef = useRef<ChatInputRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(0);
  const [scrollEscalations, setScrollEscalations] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [docFiles, setDocFiles] = useState<File[]>([]);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isOpenDocsUpload, setIsDocsOpenUpload] = useState<boolean>(false);
  const [replyTo, setReplyTo] = useState<Message | null>(null);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const hiddenDocInput = useRef<HTMLInputElement | null>(null);

  const [img, setImg] = useState("");
  const { id } = props.match.params;
  const [body, setBody] = useState("");

  const [present] = useIonToast();

  const intl = useIntl();

  const selectedChat = useSelectContactById(id);

  useEffect(() => {
    if (id) {
      props.dispatch(setRequest({ contactId: id }));
    }
  }, [id]);

  useEffect(() => {
    if (selectedChat.messages.length) {
      const lastMessage =
        selectedChat.messages[selectedChat.messages.length - 1];
      if (lastMessage.id) {
        ContactsApi.readMessages(selectedChat.contact.id, lastMessage.id);
      }
    }
  }, [selectedChat.messages.length]);

  const sendMessage = async (content: string) => {
    props.dispatch(setSendLoading(true));
    setBody("");
    setReplyTo(null);
    const { err } = await ContactsApi.postMessages(id, {
      content,
      replyTo: replyTo?.id,
    });
    props.dispatch(setSendLoading(false));
    if (err) {
      if (err.error) {
        return present({
          message: err.error?.[intl.locale],
          duration: 6000,
          color: "warning",
          position: "bottom",
        });
      }
      console.log(err);
    }
  };

  const enableOrDisableContact = async (enabled: boolean) => {
    const { err } = await ContactsApi.putEnabled(id, { enabled });
    if (err) {
      console.log(err);
    }
  };

  const solveEscalations = async (id: string) => {
    const { err } = await ContactsApi.putSolveEscalations(id);
    if (err) {
      console.log(err);
    }
  };

  const handleAutoChange = (event: CustomEvent<{ checked: boolean }>) => {
    return enableOrDisableContact(event.detail.checked);
  };

  const handleSendSubmit = async () => {
    const { err } = validateMessage(body);
    if (err) {
      return;
    }
    return sendMessage(body);
  };

  const handleSendChange = (body: string) => setBody(body);

  const openImage = (url: string) => {
    setImg(url);
    return setIsOpen(true);
  };

  const sendPhotos = async (content: FormData) => {
    setIsLoadingUpload(true);
    const { err } = await ContactsApi.uploadMedia(id, content);
    setIsLoadingUpload(false);
    if (err) {
      if (err.error) {
        return present({
          message: err.error?.[intl.locale],
          duration: 6000,
          color: "warning",
          position: "bottom",
        });
      }
      console.log(err);
    }
    setIsOpenUpload(false);
    return onHandleModalClose();
  };

  const scrollToEscalation = () => {
    setScrollEscalations(scrollEscalations + 1);
  };

  /* This is extremely risky to call a [0] index */
  const escalation = selectedChat.contact.escalated ? (
    <IonToolbar className="tool-bg-warning">
      <div className="chat-escalation-title-container">
        <div className="fw-semibold-1" style={{ textTransform: "capitalize" }}>
          <FormattedMessage id="esc.escalation" /> ·{" "}
          {selectedChat.escalations[0].type.replace(/_/g, " ")}{" "}
        </div>
        <div>{selectedChat.escalations[0].details}</div>
        <div className="mt-2 color-visito-2">
          <a onClick={scrollToEscalation}>
            <FormattedMessage id="esc.tapToView" />
          </a>
        </div>
      </div>
    </IonToolbar>
  ) : null;
  /* This is extremely risky to call a [0] index */

  const openSendPhoto = () => {
    hiddenFileInput.current?.click();
  };

  const openSendDoc = () => {
    hiddenDocInput.current?.click();
  };

  const onChangeFileInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "photo" | "doc"
  ) => {
    setIsLoadingUpload(false);
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      const files = [...fileList];

      if (type === "photo") {
        setFiles(files);
        return setIsOpenUpload(true);
      }

      setDocFiles(files);
      return setIsDocsOpenUpload(true);
    }
  };

  const onHandleModalClose = () => {
    setIsDocsOpenUpload(() => false);
    setIsOpenUpload(() => false);

    if (hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = "";
    }
    if (hiddenDocInput.current?.value) {
      hiddenDocInput.current.value = "";
    }

    setFiles([]);
    return setDocFiles([]);
  };

  const runConversational = async () => {
    try {
      const { err } = await ContactsApi.runConversational(
        selectedChat.contact.id
      );
      if (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const inputFocused = () => setTimeout(() => setIsFocus(isFocus + 1), 650);

  return (
    <IonPage id="main-content" className="chat">
      <IonHeader>
        {escalation}
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/chats"
              text={intl.formatMessage({ id: "common.back" })}
            />
          </IonButtons>
          <IonTitle
            onClick={(e) => {
              e.preventDefault();
              history.push(`/chat/${id}/contact`);
            }}
          >
            <div className="chat-title-container">
              <div
                className={classNames("user-platform", {
                  "instagram-logo": selectedChat.contact.type === "ig",
                  "whatsapp-logo": selectedChat.contact.type === "wa",
                  "messenger-logo": selectedChat.contact.type === "fm",
                  "airbnb-logo": selectedChat.contact.type === "ab",
                  "web-logo":
                    selectedChat.contact.type === "web" ||
                    selectedChat.contact.type === "play",
                  "voice-logo": selectedChat.contact.type === "voice",
                })}
              ></div>
              <div className="chat-title-profile">
                <div className="fs-4 title-name">
                  {selectedChat.contact.name}
                </div>
                {props.settings.multiAccount && (
                  <div className="fs-2 color-step-550 fw-semibold-0 title-company">
                    {selectedChat.contact.companies
                      .map((c) => c.name)
                      .join(",")}
                  </div>
                )}
              </div>
            </div>
          </IonTitle>
          <IonButtons slot="end">
            <img
              src={SparksSvg}
              width={20}
              style={{ marginRight: "6px" }}
              alt=""
            />
            <IonToggle
              checked={selectedChat.contact.enabled}
              onIonChange={handleAutoChange}
              className="visito-toggle"
              style={{ marginRight: "5px" }}
            ></IonToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ChatDetail
        contactId={selectedChat.contact.id}
        messages={selectedChat.messages}
        isEscalated={selectedChat.contact.escalated}
        openImage={openImage}
        scroll={isFocus}
        solveEscalations={solveEscalations}
        scrollEscalations={scrollEscalations}
        replyTo={({ message }: { message: Message }) => setReplyTo(message)}
      />
      <IonFooter>
        <IonToolbar>
          <div className="submit-mobile">
            <FileInput
              ref={hiddenFileInput}
              accept="image/png, image/jpeg"
              multiple
              onChange={(e) => onChangeFileInput(e, "photo")}
              style={{ display: "none" }}
            />
            <FileInput
              ref={hiddenDocInput}
              accept="application/pdf, .pdf"
              onChange={(e) => onChangeFileInput(e, "doc")}
              style={{ display: "none" }}
            />

            <ChatInput
              ref={chatInputRef}
              id="send-body"
              value={body}
              placeholder={intl.formatMessage({
                id: "chats.newMessageInput",
              })}
              enableWaActions={selectedChat.contact.type === "wa"}
              onInputChange={handleSendChange}
              onOpenPhotoInput={openSendPhoto}
              onOpenDocInput={openSendDoc}
              onRunConversational={runConversational}
              onBeforeSubmit={handleSendSubmit}
              onAfterFocus={inputFocused}
              disabled={selectedChat.contact.blocked}
              cleanReplyTo={() => setReplyTo(null)}
            />
          </div>
        </IonToolbar>
      </IonFooter>
      <MediaModal isOpen={isOpen} img={img} setIsOpen={setIsOpen} />
      <UploadPhotoModal
        show={isOpenUpload || isOpenDocsUpload}
        handleClose={onHandleModalClose}
        files={isOpenDocsUpload ? docFiles : files}
        upload={sendPhotos}
        mode="mobile"
        areDocs={isOpenDocsUpload}
        sending={isLoadingUpload}
      />
    </IonPage>
  );
};

interface ILayoutProps extends RouteComponentProps<{ id: string }> {
  dispatch: Function;
  settings: Settings;
}

export default connect((props: any) => ({
  send: props.send,
}))(Chat);
